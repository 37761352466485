import Service from "../Service";

const resource = "qryconfiguration/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    querygeneral(obj, requestID) {
        return Service.post(resource + "querygeneral", obj, {
            params: { requestID: requestID },
        });
    },

    downloadexcel(obj, requestID) {
        return Service.post(resource + "downloadexcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },


    execuetSQL(obj, requestID) {
        return Service.post(resource + "execuetSQL", obj, {
            params: { requestID: requestID },
        });
    },

    downloadexcelTransactSQL(obj, requestID) {
        return Service.post(resource + "downloadexcelTransactSQL", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

};